/* ================================================================
	SINGLE PRODUCT
	================================================================ */

#product {

}

// IMAGES
.product-icons {
  list-style: none;
  margin: $padding 0;
  @include clearfix;
}

.product-icon {
  display: inline-block;
  float: left;
  margin-right: $padding-half;
  margin-bottom: $padding-half
}

// BOX
.product-box {
  padding: $padding;
  background: $product-bg;
  font-size: 16px;
}

.product-title {
  font-weight: bold;
  margin: 0 0 $padding-half;
}

.product-rating {
  color: gold;
  font-size: 120%;
  @include clearfix;
  margin-bottom: $padding-half;
}

.product-rating .fa {
  float: left;
}

// VARIATIONS
.product-variations ul {
  list-style: none;
  margin: $padding-quarter 0;
  @include clearfix;
}

.product-variations li {
  margin-bottom: 3px;
  display: inline-block;
  float: left;
  width: 100%;

  @include notebook-up {
    width: 32%;
    margin-right: 2%;

    &:nth-child(3n+3) {
      margin-right: 0;
    }
  }

  @include tablet {
    width: 48%;
    margin-right: 4%;

    &:nth-child(2n+2) {
      margin-right: 0;
    }
  }
}

.variation {
  display: block;
  padding: 5px 10px;
  background: rgba(#000, .1);
  text-align: center;

  &:hover {
    background: $primary;
    color: #fff;
  }
}

.variation-form {
  @include clearfix;
}

.variation-form .form-group {
  margin-bottom: 3px;
  float: left;

  @include notebook-up {
    width: 32%;
    margin-right: 2%;

    &:last-child {
      margin-right: 0;
    }
  }

  @include mobile-tablet {
    width: 100%;
  }
}

// ERSPARNIS
.product-savings {
  color: red;
  margin-top: $padding-half;
}

// PRICE
.product-price {
  font-size: $font-size-h3;
  font-weight: 600;
}

.product-price ins {
  text-decoration: none;
}

.product-price .msrp-price {
  font-weight: 400;
  font-size: $font-size-h4;
  color: $text-color-light;
}

#SCPcustomOptionsDiv {
  display: none;
}

// CART FORM
.cart {
  font-size: 22px;
}

.cart .quantity {
  @include tablet-up {
    max-width: 30%;
  }
}

.quantity .form-control {
  border: 0;
  height: 54px;
  width: 100%;
}

.add_to_cart_button {
  height: 54px;
  background: $matramaxx-green;
  color: #fff;
  text-transform: uppercase;

  &:hover {
    background: darken($matramaxx-green, 10%);
    color: #fff;
  }

  @include mobile-tablet {
    width: 100%;
  }
}

.product-options-bottom .tax-details {
  margin: 0 0 10px;
  display: inline-block;
}

.cross-price .tax-details,
.product-cross + a {
  display: none;
}

.cross-price .price-box {
  display: inline;
}

.product-images {
}

.carousel-control.right,
.carousel-control.left{
  background-image:none;
}

.price_info_box .product-price {
  display: inline-block;
}

.price_info_box  .tax-details {
 font-size: 11px;
  display: block;
}

.category-products .lead {
  margin-bottom: 10px;

}
.category-products .desc {
  margin-bottom: 10px;
}

.btn-success {
  background-color:#a0d815;
}

.carousel-caption{
  background: #f3f3f3;
  width: 100%;
  max-width: 100%;
  left: 0;
  position: relative;
  text-shadow: none;
  color: #777;
  padding-bottom: 20px;
}


@media screen and (max-width: $screen-sm-min) {
  .carousel-caption {
    padding: 10px 0;
  }
}

.carousel-indicators {
  bottom: 80px;
}
