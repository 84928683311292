// IMPORT ALL VARS
@import '../bootstrap/variables';
@import '../font-awesome/variables';
@import 'mixins';

/*	================================================================
	BOOTSTRAP VARS OVERRIDE
	================================================================ */

	$primary: #e6b599;
	$secondary: #4C010A;
	$brand-primary: $primary;

	$matramaxx-blue: #29a7d7;
	$matramaxx-green: #a0d815;

	$gray-lighter: lighten($gray-base, 70%); // #eee
	$body-bg: #fff;
	$product-bg: #f3f3f3;

	// LINKS
	$link-color:            $brand-primary;
	$link-hover-color:      darken($link-color, 15%);
	$link-hover-decoration: none;

	// TYPO
	$text-color: #000;
	$text-color-light: #777;
	//$line-height-base: 1.6;
	$font-size-base: 16px;
	$font-family-base:  'Raleway', sans-serif;
	$font-size-h1: 28px;

	$border-color: rgba($secondary, .15);
	$hr-border: $border-color;

	//$grid-gutter-width: 40px;

/*	================================================================
	MISC CSS VARS
	================================================================ */

	$fonts: "../fonts";
	$images: "../img";
	$border-radius-base: 4px;

	// PADDINGS
	//$grid-gutter-width : 30;
	$padding: 30px;
	$padding-double: $padding * 2;
	$padding-half: $padding / 2;
	$padding-quarter: $padding / 4;

	// WC COLORS
	$red: #E41D3D;
	$blue:#323c8d;
	$yellow:#f9b929;
	$brown:#845300;
	$green:#00823c;

	// SOCIAL MEDIA COLORS
	$facebook-blue: #3b5998;
	$twitter-blue: #55acee;
	$google-red: #dd4b39;
	$youtube-red: #bb0000;
	$linkedin-blue: #007bb5;
	$instagram-yellow: #e95950;
	$instagram-blue: #125688;
	$whatsapp-green: #4dc247;
	$pinterest-red: #cb2027;
	$rss-orange: #ff6600;
	$wordpress-blue:#21759b;
