.cart {
  .page-title .checkout-types li {
    margin: 0 0 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .title-buttons .checkout-types {
    float: right;
    margin-bottom: 0;

    li {

    }
  }

  .checkout-types .paypal-or {
    margin: 0 8px;
  }
  .totals .checkout-types .paypal-or {
    clear: both;
    display: block;
    padding: 8px 55px 0 0;
    font-size: 12px;
  }

  > form {
    margin-bottom: 18px;
  }

  @media (max-width: 480px) {
    > form {
      width: 100%;
      overflow-y: hidden;
      overflow-x: scroll;
      padding: 15px;
      border: 1px solid #ccc;
    }
  }
}

// Button
.btn-checkout {

}

// Shopping Cart Table
.cart-table {
  margin-bottom: 0;

  .qty {
    width: auto;
    text-align: center;
  }

  .button {
  }

  .btn-continue {
    float: left;
  }
}

// Cross-sell
.cart .crosssell {

  #crosssell-products-list {
    margin-bottom: 0;
    padding: 15px;
  }

  li.item {
    padding: 10px 0;
    border-bottom: 1px solid #ccc;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  .product-image {
    float: left;
  }
  .product-details {
    margin-left: 100px;
  }
  .add-to-links {
    .separator {
      display: none;
    }
  }

  .link-compare {
    font-weight: normal;
  }

}

// Discount Codes & Estimate Shipping and Tax Boxes
.cart .discount,
.cart .shipping {

}

// Shopping Cart Totals
.cart .totals {

  table {
  }

  .checkout-types {
    margin-bottom: 0;
    text-align: right;

    li {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Options Tool Tip
.item-options {

  dd {
    margin-left: 15px;
  }
}

.truncated {
  position: relative;
  cursor: help;

  .dots {
    cursor: inherit;
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
}

.truncated_full_value {

  dl {
    margin: 0;
  }

  dt {
  }

  dd {
    margin-left: 0;
  }

  // Multiple lists, add a border at the top
  dd + dt {
    border-radius: 0;
  }

  &.show {
    display: block;
  }
}

.title-buttons {
  overflow: hidden;
}


.btn-checkout {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  line-height: 1.42857;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
  background: #a0d815;
  font-size: 24px;
  color: #fff;
  text-align: center;
  padding: 0 15px;
  position: relative;
  top: 4px;
  height: 40px;
  background: #a0d815;
  color: #fff;
}

.checkout-cart-index .page-title h1 {
  float: left;
}

#shopping-cart-table h2,
#shopping-cart-table,
.checkout-cart-index #main-content {
  font-size: 16px;
}

.checkout-cart-index .cart {
  font-size: 16px;
}

.checkout-cart-index .totals .btn-checkout {
  margin-top: 20px;
  float: right;
}

.cart-empty {
  min-height: 500px;
  clear: both;
}

.checkout-onepage-index #topbar,
.checkout-onepage-index .navbar,
.checkout-onepage-index #newsletter,
.checkout-onepage-index #footer
{
  display: none;
}

.opc .section {
  margin:0;
}

#checkout-step-review .data-table {
  margin-bottom: 0px;
}
.opc .buttons-set {
  margin-top: 0px!important;
  padding: 20px;
  border-top: 1px solid #e5e5e5;
  line-height: 34px;
  text-align: right;
}

.checkout-onepage-index .page-title {
  display: none;
}

.checkout-agreements .agree input.checkbox {
  float: left;
  margin-top: 12px!important;
  margin-right: 6px;
}

.opc .checkout-agreements li, .checkout-multishipping-overview .checkout-agreements li {
  margin: 0!important;
}