/*	================================================================
	GLOBAL MIXINS
	================================================================ */

@import "media";

	/*	================================================================
		GRADIENTS
		================================================================ */

	@mixin gradient($fromColor, $toColor) {
		background-color: $fromColor; /* Old browsers */
		background-image: -moz-linear-gradient(top,  $fromColor 0%, $toColor 100%); /* FF3.6-15 */
		background-image: -webkit-linear-gradient(top,  $fromColor 0%,$toColor 100%); /* Chrome10-25,Safari5.1-6 */
		background-image: linear-gradient(to bottom,  $fromColor 0%,$toColor 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	}


	@mixin simple-gradient {
		$fromColor:rgba(#000000, 0);
		$toColor:rgba(#000000,0.15);

		background-image: -moz-linear-gradient(top,  $fromColor 0%, $toColor 100%); /* FF3.6-15 */
		background-image: -webkit-linear-gradient(top,  $fromColor 0%,$toColor 100%); /* Chrome10-25,Safari5.1-6 */
		background-image: linear-gradient(to bottom,  $fromColor 0%,$toColor 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	}

	@mixin diagonal-gradient($fromColor, $toColor) {
		background-color: $fromColor; /* Old browsers */
		background-image: -moz-linear-gradient(45deg,  $fromColor 0%, $toColor 100%); /* FF3.6-15 */
		background-image: -webkit-linear-gradient(45deg,  $fromColor 0%,$toColor 100%); /* Chrome10-25,Safari5.1-6 */
		background-image: linear-gradient(45deg,  $fromColor 0%,$toColor 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	}

	/*	================================================================
		BORDER RADIUS
		================================================================ */

	@mixin radius($radius:$border-radius-base) {
		@if $radius {
			border-radius: $radius;
			background-clip: padding-box;  /* stops bg color from leaking outside the border: */
		}
	}

	/*	================================================================
		BOX SHADOW
		================================================================ */

	@mixin shadow($shadow:0 0 10px rgba(#000,0.15)) {
		box-shadow: $shadow;
	}

	/*	================================================================
		FONT AWESOME
		================================================================ */

	@mixin font-awesome {
		font-family: "FontAwesome";
	}

	/*	================================================================
		SIDE-RADIUS
		================================================================ */

	@mixin side-radius($side, $radius:$border-radius-base) {
		@if ($side == left or $side == right) {
			border-bottom-#{$side}-radius: $radius;
			border-top-#{$side}-radius: $radius;
			background-clip: padding-box;  /* stops bg color from leaking outside the border: */
		} @else {
			border-#{$side}-left-radius: $radius;
			border-#{$side}-right-radius: $radius;
			background-clip: padding-box;  /* stops bg color from leaking outside the border: */
		}
	}

	/*	================================================================
		INSET SHADOW
		================================================================ */

	@mixin inset-shadow($active:true) {
		box-shadow: $shiny-edge-size $shiny-edge-color inset;

		@if $active { &:active {
			box-shadow: $shiny-edge-size $shiny-edge-active-color inset; }
		}
	}

	/*	================================================================
		TRANSITION
		================================================================ */

	@mixin transition($transition:all 300ms ease-out) {
		-webkit-transition: $transition;
		transition: $transition;
	}

	/*	================================================================
		BOX-SIZING
		================================================================ */

	@mixin box-sizing($type:border-box) {
		box-sizing: $type; // Chrome, IE 8+, Opera, Safari 5.1
	}

	/*	================================================================
		TRIANGLE
		================================================================ */

	@mixin css-triangle($triangle-size, $triangle-color, $triangle-direction) {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border: inset $triangle-size;
		@if ($triangle-direction == top) {
			border-color: $triangle-color transparent transparent transparent;
			border-top-style: solid;
		}
		@if ($triangle-direction == bottom) {
			border-color: transparent transparent $triangle-color transparent;
			border-bottom-style: solid;
		}
		@if ($triangle-direction == left) {
			border-color: transparent transparent transparent $triangle-color;
			border-left-style: solid;
		}
		@if ($triangle-direction == right) {
			border-color: transparent $triangle-color transparent transparent;
			border-right-style: solid;
		}
	}

	/*	================================================================
		CLEARFIX
		================================================================ */

	@mixin clearfix {
		&:after {
			display: table;
			content: " ";
			clear:both;
		}
	}

	/*	================================================================
		GLOWING
		================================================================ */

	@mixin glow($selector:focus, $fade-time:300ms, $glowing-effect-color:fade-out($primary-color, .25)) {
		transition: box-shadow $fade-time, border-color $fade-time ease-in-out;

		&:#{$selector} {
			box-shadow: 0 0 5px $glowing-effect-color;
			border-color: $glowing-effect-color;
		}
	}

	/*	================================================================
		TRANSFORMS
		================================================================ */

	@mixin translate2d($horizontal:0, $vertical:0) {
		transform: translate($horizontal,$vertical)
	}

	@mixin scale($size) {
		-webkit-transform: scale($size);
		transform: scale($size);
	}

	@mixin rotate($deg) {
		-webkit-transform: rotate(#{$deg}deg);
		transform: rotate(#{$deg}deg);
	}

	@mixin transform($type) {
		-webkit-transform: $type;
		transform: $type;
	}

	/*	================================================================
		ANIMATION
		================================================================ */

	@mixin animation($type) {
		-webkit-animation: $type;
		animation: $type;
	}

	/*	================================================================
		INVISIBILITY
		================================================================ */

	@mixin element-invisible {
		position: absolute !important;
		height: 1px;
		width: 1px;
		overflow: hidden;
		clip: rect(1px, 1px, 1px, 1px);
	}

	// Turns off the element-invisible effect.
	@mixin element-invisible-off {
		position: static !important;
		height: auto;
		width: auto;
		overflow: visible;
		clip: auto;
	}

	/*	================================================================
		PLACEHOLDER TEXT
		================================================================ */

	@mixin placeholder($color: $input-color-placeholder) {
		// Firefox
		&::-moz-placeholder {
			color: $color;
			opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
		}
		&:-ms-input-placeholder { color: $color; } // Internet Explorer 10+
		&::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
	}

	/*	================================================================
		RANGES
		================================================================ */

	@function lower-bound($range){
		@if length($range) <= 0 {
			@return 0;
		}
		@return nth($range,1);
	}

	@function upper-bound($range) {
		@if length($range) < 2 {
			@return 999999999999;
		}
		@return nth($range, 2);
	}

	/*	================================================================
		MEDIA QUERYS
		================================================================ */

	// FOR RETINA DISPLAYS
	@mixin retina {
		@media
			only screen and (-webkit-min-device-pixel-ratio: 2),
			only screen and (min--moz-device-pixel-ratio: 2),
			only screen and (-o-min-device-pixel-ratio: 2/1),
			only screen and (min-device-pixel-ratio: 2),
			only screen and (min-resolution: 192dpi),
			only screen and (min-resolution: 2dppx) {
			@content;
		}
	}

	// XLARGE >= 1281px
	@mixin desktop {
		@media #{$desktop-up} {
			@content;
		}
	}

	// LARGE >= 992px - 1280px
	@mixin notebook {
		@media #{$notebook-only} {
			@content;
		}
	}

	@mixin notebook-up {
		@media #{$notebook-up} {
			@content;
		}
	}

	// TABLETS > 768px - 991px
	@mixin tablet {
		@media #{$tablet-only} {
			@content;
		}
	}

	@mixin tablet-up {
		@media #{$tablet-up} {
			@content;
		}
	}

	// NAV BREAK <= 767px
	@mixin nav-break {
		@media #{$nav-break} {
			@content;
		}
	}

	// TABLETS + SMARTPHONES <= 991px
	@mixin mobile-tablet {
		@media #{$mobile-tablet} {
			@content;
		}
	}

	// SMARTPHONES <= 641px
	@mixin mobile {
		@media #{$mobile-only} {
			@content;
		}
	}

	// PRINT
	@mixin print {
		@media print {
			@content;
		}
	}

	/*	================================================================
		REM CALC
		================================================================ */

	// STRIP UNIT
	// It strips the unit of measure and returns it
	@function strip-unit($num) {
		@return $num / ($num * 0 + 1);
	}

	// CONVERT TO REM
	@function convert-to-rem($value, $base-value: $font-size-base)  {
		$value: strip-unit($value) / strip-unit($base-value) * 1rem;
		@if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
		@return $value;
	}

	@function rem($values, $base-value: 10px) {
		$max: length($values);

		@if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }

		$remValues: ();
		@for $i from 1 through $max {
			$remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
		}
		@return $remValues;
	}
