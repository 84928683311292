.topbar-custom {
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 50px;
  text-align: center;
  font-family: "Open Sans", "Raleway", sans-serif;
  font-size: 22px;
  margin-bottom: 0;
  display: flex;
}
.coupon-mobile {
  display: none;
}
@media (max-width: 767px) {
  .topbar-custom {
    font-size: 14px;
    height: 40px;
  }
  .coupon-desktop {
    display: none;
  }
  .coupon-mobile {
    display: block;
  }
}
