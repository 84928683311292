/*	================================================================
	HEADER
	================================================================ */

	#main-header {
		padding: $padding-half 15px;
	}

	.header-logo {
		@include mobile {
			text-align: center;
		}
	}

	.header-content {
		text-align: right;
		//padding:$padding-half 0;
		color: $text-color-light;
		line-height: 64px;

		@include mobile {
			text-align: center;
		}
	}

		.header-content div {
			display:inline-block;
		}

		// PHONE
		.header-phone {
			font-size: $font-size-h2;
			margin-right: $padding-half;

			@include mobile {
				font-size: $font-size-h4;
			}
		}
			.header-phone .fa {
				margin-right: 5px;
			}

		// LOGOS
		.header-logos img {
			margin: 0 $padding-half;

			@include mobile {
				height: 30px;
			}
		}

		// CART
		.cart-link {
			margin-left: $padding-half;
			display: block;
			background: $matramaxx-green;
			font-size: 36px;
			color: #fff;
			text-align: center;
			padding:0 $padding;
			position: relative;
			top:4px;

			&:hover {
				background: darken($matramaxx-green, 10%);
				color: #fff;
			}

			@include mobile {
				width: 100%;
			}
		}
