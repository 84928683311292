/*	================================================================
	RESET
	================================================================ */

*,
*:before,
*:after {
	padding: 0;
	margin: 0;
	outline: 0;
	@include box-sizing(border-box);
}

/*	================================================================
	BASE
	================================================================ */

	a {
		color: inherit;
		text-decoration:none;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	img {
		border: 0;
		vertical-align: middle;
		max-width:100%;
		display: inline-block;
		-ms-interpolation-mode: bicubic;
		height:auto;
	}

	a,
	.btn,
	button,
	input {
		@include transition;
	}
