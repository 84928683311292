/*	================================================================
	MEDIA QUERYS
	================================================================ */

	// Media queries breakpoints
	// Extra small screen / phone
	$screen-xs:                  480px !default;
	$screen-xs-min:              $screen-xs !default;
	$screen-phone:               $screen-xs-min !default;

	// Small screen / tablet
	$screen-sm:                  768px !default;
	$screen-sm-min:              $screen-sm !default;
	$screen-tablet:              $screen-sm-min !default;

	// Medium screen / desktop
	$screen-md:                  992px !default;
	$screen-md-min:              $screen-md !default;
	$screen-desktop:             $screen-md-min !default;

	// Large screen / wide desktop
	$screen-lg:                  1200px !default;
	$screen-lg-min:              $screen-lg !default;
	$screen-lg-desktop:          $screen-lg-min !default;

	// So media queries don't overlap when required, provide a maximum
	$screen-xs-max:              ($screen-sm-min - 1) !default;
	$screen-sm-max:              ($screen-md-min - 1) !default;
	$screen-md-max:              ($screen-lg-min - 1) !default;

	// MEDIA QUERY
	$screen: "only screen";

	// SMARTPHONES <= 767px
	$mobile-up: $screen;
	$mobile-only: "#{$screen} and (max-width: #{$screen-xs-max})";

	// SMARTPHONES + TABLETS <= 991px
	$mobile-tablet: "#{$screen} and (max-width:#{$screen-sm-max})";

	// NAV BREAK
	$nav-break: "#{$screen} and (max-width:#{$screen-sm-min - 1})";

	// TABLET >= 768px <= 991px
	$tablet-up: "#{$screen} and (min-width:#{$screen-sm-min})";
	$tablet-only: "#{$screen} and (min-width:#{$screen-sm-min}) and (max-width:#{$screen-sm-max})";

	// SMALL DESKTOPS >= 992px <= 1999px
	$notebook-up: "#{$screen} and (min-width:#{$screen-md-min})";
	$notebook-only: "#{$screen} and (min-width:#{$screen-md-min}) and (max-width:#{$screen-md-max})";

	// LARGE DESKTOPS > 1200px
	$desktop-up: "#{$screen} and (min-width:#{$screen-lg-min})";

	// Legacy
	$tablet: $tablet-up;
	$notebook: $notebook-up;
	$desktop: $desktop-up;
