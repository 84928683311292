//SETTINGS, TOOLS
@import "tools/settings";
@import "tools/animations";
@import "bootstrap";
@import "font-awesome/font-awesome";

// OVERWRITE CUSTOM MIXINS
@import 'tools/mixins';

// IMPORT BASE STYLES
@import "base/base";

// IMPORT AREAS
@import "base/topbar";
@import "base/header";
@import "base/nav";
@import "base/content";
@import "base/sections";
@import "base/product";
@import "base/sidebar";
@import "base/footer";
@import "base/misc";
@import "base/cart";
@import "base/review";
@import "base/compare-table";
@import "base/amazonpay";
