.apa .step-title h2 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
}
.discount-form {
  margin-top: 20px;
  margin-bottom: 20px;
}
#coupon_code_apply_button {
  margin-top: 10px;
}
#checkout-review-table-wrapper {
  border: 1px solid black;
}
#checkout-review-table {
  width: 100%;
}
#checkout-review-table td, th {
  border: 1px solid #dddddd;
  padding: 8px;
}
#checkout-review-table .a-right {
  text-align: right;
}
#checkout-review-table tfoot {
  background-color: #FBFAF6;
}
#checkout-review-table .first.last.odd {
  background-color: #F9F9F9;
  vertical-align: top;
}
#checkout-review-table td.last {
  text-align: right;
}
#checkout-review-table td.a-center {
  text-align: center;
}
.agreement-box {
  margin-bottom: 20px;
}
.checkout-agreements {
  margin-top: 30px;
  margin-bottom: 30px;
}
#shippingMethodWidgetDiv li {
  list-style-type: none;
}
#checkout-review-table .first.odd {
  background-color: #F9F9F9;
}
#checkout-review-table .even {
  background-color: #F9F9F9;
}
#checkout-review-table .last.odd {
  background-color: #F9F9F9;
}
#checkout-review-table .odd {
  background-color: #F9F9F9;
}
#checkout-review-table .product-name {
  font-size: 18px;
  color: #666666;
}
.agreement-link {
  margin-left: 0px;
  color: #29a7d7;
}
