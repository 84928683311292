/*	================================================================
	FOOTER
	================================================================ */

#footer {
	background: #fafafa;
	padding: $padding-double 0;
	color: $text-color-light
}
	#footer .row > div {
		margin-bottom: $padding;
	}

	.footer-headline {
		font-size: 26px;
		margin-bottom: $padding;
		display: block;
		color: black;
	}

	.footer-menu {
		color: $text-color-light;
		list-style: none;
		margin-bottom: $padding;
	}
