//colors
$color_bahia_approx: #a0ce00;
$color_pumice_approx: #c7c7c7;
$color_dove_gray_approx: #6b6b6b;
$color_cerulean_approx: deepskyblue;

//fonts
$font_0: Raleway;
$font_1: sans-serif;


.fa-ul.compare {
    margin-left: 30px;
}
.comparison-table {
    padding: 20px;
}


.text-center {
    .btn-protex {
        background-color: $color_bahia_approx;
        border-color: $color_bahia_approx;
    }
    .price-tag {
        font-size: 22px;
        font-family: $font_0, $font_1;
    }
}

@media only screen and(max-width: 1199px) {
    .comparison-table .card-left {
        border-right: 0px;
    }
}

.ticker {
    background: $color_dove_gray_approx;
    width: 5px;
    height: 28px;
    position: relative;
    left: 58%;
    top: 24px;
    margin: 0;
    padding: 0;
    opacity: 0.9; //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    border-color: $color_cerulean_approx;
    &.ticker-right {
        left: 78%;
    }
    &.ticker-left-kaltschaum {
        left: 38%;
    }
    &.ticker-right-kaltschaum {
        left: 58%;
    }
    &.ticker-left-visco {
        left: 30%;
    }
    &.ticker-right-visco {
        left: 55%;
    }
}

h3.compare-heading {
    padding-top: 25px;
    padding-bottom: 25px;
    font-family: $font_0, $font_1;
    margin-top: 0;
    margin-bottom: 0;
}

ul.fa-ul.compare-text li {
    font-size: 16px;
    line-height: 2.2;
    font-family: $font_0, $font_1;
}

.card-body.compare-card {
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-height: 707px;
}

.img-responsive.compare-img {
    padding: 20px;
    display: block;
    width: 100%;
    height: auto;
    padding-bottom: 0;
}

.card-text.header {
    line-height: 1.8;
    font-size: 18px;
    font-family: $font_0, $font_1;
    letter-spacing: 0.01em;
}

.comparison-table .card-left {
    border-right: 1px solid $color_pumice_approx;
}

.fa.protex-check {
    color: $color_bahia_approx;
}
