/*	================================================================
	NAV
	================================================================ */

.navbar {
  background: #fafafa;
  text-transform: uppercase;
  font-family: "Open Sans", "Raleway", sans-serif;
  font-weight: 600;
  margin-bottom: $padding;
}

.navbar-sticky {
  display: none;
  @extend .navbar;
  background: rgba(#fafafa, .9);
  position: fixed;
  width: 100%;
  top: 0px;
  margin-bottom: 0px;
  @include transition;
  z-index: 9999;
  @include shadow;

  @include mobile {
    display: none !important;
  }
}

.navbar-sticky img {
  height: 30px;
  margin-right: $padding;
}

// MOBILE TOGGLE
.navbar-toggle {
  @include mobile {
    display: block;
    background: $primary;
    float: none;
    margin: 0;
    @include radius(0);
    border: 0;
    width: 100%;

    &.collapsed {
      background: none;
    }
  }
}

.navbar-toggle .fa {
  margin-right: 5px;
}

// <ul>
.navbar-nav {
  @include tablet-up {
    float: none;
    text-align: center;
  }
}

// <li>
.nav > li {
  display: inline-block;

  @include mobile {
    display: block;
  }
}

.navbar-nav > li {
  @include tablet-up {
    float: none;
  }
}

// <a>
.nav > li > a:hover,
.nav > li > a:focus,
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background: $primary;
  background: $matramaxx-blue;
  color: #fff;
}

.bs-prototype-override .dropdow-menu,
.navbar-nav>li.bs-prototype-override>.dropdown-menu {
  display: block!important;
}
