#reviews{
  .btn_star{
    margin: 4px 0;

    .rating-bar-wrapper{
      height:20px;
      background-color:#efefcd;

      .rating-bar{
        height: 20px;
        background-color: #ffcc68;
      }
    }
  }

  .fa-star, .fa-star-half-o, .fa-star-o{
    color: #ffcc68;
  }

  .pagination>li.active>a {
    background: #ffcc68;
    border-color: #ddd;
    color: #fff;
  }

  .pagination>li>a {
    color: #ddd;
  }


  #review-form .buttons-set {
    margin:0;
  }

  #review-form .star-rating {
    font-size: 0;
    white-space: nowrap;
    display: inline-block;
    width: 150px;
    height: 30px;
    overflow: hidden;
    position: relative;
    background: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjREREREREIiBwb2ludHM9IjEwLDAgMTMuMDksNi41ODMgMjAsNy42MzkgMTUsMTIuNzY0IDE2LjE4LDIwIDEwLDE2LjU4MyAzLjgyLDIwIDUsMTIuNzY0IDAsNy42MzkgNi45MSw2LjU4MyAiLz48L3N2Zz4=');
    background-size: contain;
    margin-bottom: 15px;
  }
  #review-form .star-rating i {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 20%;
    z-index: 1;
    background: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjRkZERjg4IiBwb2ludHM9IjEwLDAgMTMuMDksNi41ODMgMjAsNy42MzkgMTUsMTIuNzY0IDE2LjE4LDIwIDEwLDE2LjU4MyAzLjgyLDIwIDUsMTIuNzY0IDAsNy42MzkgNi45MSw2LjU4MyAiLz48L3N2Zz4=');
    background-size: contain;
  }
  #review-form .star-rating input {
    -moz-appearance: none;
    -webkit-appearance: none;
    opacity: 0;
    display: inline-block;
    width: 20%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 2;
    position: relative;
  }
  #review-form .star-rating input:hover + i,
  #review-form .star-rating input:checked + i {
    opacity: 1;
  }
  #review-form .star-rating i ~ i {
    width: 40%;
  }
  #review-form .star-rating i ~ i ~ i {
    width: 60%;
  }
  #review-form .star-rating i ~ i ~ i ~ i {
    width: 80%;
  }
  #review-form .star-rating i ~ i ~ i ~ i ~ i {
    width: 100%;
  }
  #review-form .choice {
    float:left;
    padding: 10px;
    line-height: 15px;
  }
}