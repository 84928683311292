/*	================================================================
	CSS3 ANIMATIONS
	================================================================ */

// PULSE
@keyframes pulse {
	from {transform: scale3d(1, 1, 1)}
	50% {transform: scale3d(1.05, 1.05, 1.05)}
	to {transform: scale3d(1, 1, 1)}
}
.pulse {animation: pulse 1s infinite}

// FLIP
@keyframes flip {
	from {transform: rotateY(0)}
	50% {transform: rotateY(90deg)}
	to {transform: rotateY(180deg)}
}
