/*	================================================================ *\
	SECTIONS
	================================================================ */

	.section {
		margin-top: $padding-double;
		margin-bottom: $padding-double;
	}

	.section-headline {
		font-weight: 300;
		font-size: $font-size-h1;
		margin-bottom: $padding;

		@include mobile-tablet {
			font-size: $font-size-h2;
		}
	}

		// FLIEßTEXT
		.section p {
			color: $text-color-light;
			line-height: 34px;
		}

/*	================================================================
	SERVICE ICONS
	================================================================ */

	#service-icons {
		text-align: center;
	}
		.service-icon img {
			margin-bottom: $padding-half;
		}

/*	================================================================
	PRODUKTBESCHREIBUNG
	================================================================ */

	#product-desc {  }

/*	================================================================
	PRODUKT VORTEILE
	================================================================ */

	.product-vorteile {
		color: #fff;
		background: #54565b;
		padding: $padding $padding-half;
		font-size: 20px;
	}
		.product-vorteile .h2 {
			font-weight: 600;
			margin: 0 0 $padding;
			display: block;
		}

		.product-vorteile .fa {
			color: $primary;
		}

		.product-vorteile li {
			margin-bottom: $padding-half;
		}

/*	================================================================
	PRODUKT SLIDER
	================================================================ */

	#product-slider {
		min-height: 600px;
		background-image: url(#{$images}/bild-02.jpg);
		background-position: top center;
		background-size: cover;

		@include notebook-up {
			min-height: 600px;
		}

		@include tablet {
			min-height: 400px;
		}

		@include mobile {
			min-height: 200px;
		}
	}

/*	================================================================
	PRODUKT FAQ
	================================================================ */

	#product-faq .section-headline {
		text-align: center;
	}

/*	================================================================
	TESTIMONIALS
	================================================================ */

	#testimonials {
		padding: $padding-double 0;
		background: $primary;
		color: #fff;
		text-align: center;
	}

		#testimonials .section-headline {color: #fff}
		#testimonials p {color: #fff}

		#testimonials .carousel-indicators {bottom: -40px}
		#testimonials .carousel-indicators li {border: 2px solid #fff}
		#testimonials .carousel-indicators li.active {background: #fff}

/*	================================================================
	PAYMENTS
	================================================================ */

	#payments {
		padding-top:$padding-double;
		padding-bottom: $padding-double;
		text-align: center;
	}

		#payments img {
			margin: 0 $padding-half;
		}

/*	================================================================
	CROSS SELLING
	================================================================ */

	#crossselling {
		text-align: center;
		background: #fafafa;
		padding: $padding-double 0;
		margin-bottom: 0;
	}
		.product-cross {
			display: inline-block;
			margin-top: $padding;
		}
			.product-cross:hover {
				@include scale(1.1);
			}

		.cross-title {
			color: $text-color-light;
		}

		.cross-price {
			font-weight: 600;
			font-size: 28px;
			margin-top: $padding-half;
		}

/*	================================================================
	PRODUKT NEWSLETTER
	================================================================ */

	#newsletter {
		text-align: center;
		padding: $padding-double 0;
		margin-top: $padding-double;
		background: #292c32;
		color: #fff;
	}
		#newsletter p {
			color: $text-color-light;
			margin: $padding-half 0;
		}

		.newsletter-form .form-control {
		}

			.newsletter-form .btn,
			.newsletter-form .form-control {
				height: 60px;
				@include radius(0);
				border: 0;
				width: 100%;

				@include tablet-up {
					width: 350px;
				}

				@include mobile {
					margin-bottom: $padding-quarter;
				}
			}

			.newsletter-form .btn {
				background: $matramaxx-green;
				text-transform: uppercase;
			}
