/*	================================================================ *\
	CONTENT
	================================================================ */
.green-box, .blue-box {
  background: #e6b599;
  padding: 10px;
  margin-top: 15px;
}
.green-box .inner, .blue-box .inner {
  color: #fff;
  border:5px solid #fff;
  padding: 15px;
  text-align: center;
}