/*	================================================================
MISC
================================================================ */

// SLIDEDOWN
.slidedown-title {cursor: pointer}
.slidedown-content {display: none}

// RESPONSIVE VIDEO
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
	.videoWrapper iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

// AKKORDEON (collapse.js)
// <a> TOGGLE
.panel-title a {
	display: block;
	padding: 10px 10px 10px $padding-double;
	background: $product-bg;
}

	.panel-title a:before {
		@include font-awesome;
		content: $fa-var-plus;
		color: $primary;
		position: absolute;
		left: 40px;
	}
		.panel-title a.collapsed:before {
			content: $fa-var-minus;
		}

	// CONTENT
	.panel .panel-body {
		padding-left: $padding-double;
	}

// TABELLEN
.table>tbody>tr>th,
.table>tbody>tr>td,
.table>tfoot>tr>th,
.table>tfoot>tr>td {
	border-top: 0;
	padding: $padding-half;
	border-right: 5px solid #fff;
	border-bottom: 5px solid #fff;

	&:first-child {
		background: $product-bg;
		font-weight: 600;
	}

	&:last-child {
		background: #fafafa;
	}
}

// list in Content
.std{
	ul, ol{
		padding-left:40px;
	}
}

// BACK TO TOP
.back-to-top {
	cursor: pointer;
	position: fixed;
	bottom: 50px;
	right: 20px;
	display:none;
	width: 52px;
	height: 52px;
	@include radius(50%);
	background: $primary;
	border: 0;
}

// KAROUSEL
.carousel-indicators li {border: 2px solid $primary}
.carousel-indicators li.active {background: $primary}

// placeholder
.form-control::-webkit-input-placeholder { color: #777; }  /* WebKit, Blink, Edge */
.form-control:-moz-placeholder { color: #777; }  /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder { color: #777; }  /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder { color: #777; }  /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder { color: #777; }  /* Microsoft Edge */

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */