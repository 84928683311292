/*	================================================================
	SIDEBAR
	================================================================ */

.sidebar {
	.headline {
		color:$gray;
		text-transform: uppercase;
		border: none;
		margin-top:rem(100);
		display:block;
	}

	.article {
		border-bottom:1px dashed $primary;
		padding:rem(10 0);
		font-size: rem(14);

		&:last-child {
			border:none;
		}
	}

	.article-date {
		font-size: rem(14);
	}
	.article-title {
		font-weight: 700;
	}

	.article-title,
	.article-date  {
		display:block;
		margin-bottom: rem(10);
		color: $gray-light;
	}
}

/*	================================================================
	SIDENAV
	================================================================ */

.sidenav {}

.sidenav-menu {
	list-style:none;
	margin-top:rem(115);
	width:rem(150);

	a {
		display:block;
		padding:rem(5 10);
		@include transition;
		border-bottom:1px dashed $primary;
		font-weight: 300;
		text-transform: uppercase;
		color: $text-color;
		font-size: rem(18);

		&:hover {
			color:$secondary;
		}
	}

	li:last-child a {border:none}
	li.current-menu-item a{
		color:$secondary;
	}
}
